import React from 'react'
import Img from 'gatsby-image'
import { graphql, useStaticQuery, Link } from 'gatsby'

const SearchPostCard = ({ title, featuredImage, link }) => {
  return (
    <div className="search-post-card">
      <Img fluid={featuredImage} className="search-post-card__image" />
      <div className="search-post-card__wrapper">
        <div className="search-post-card__sub-heading">FREIGHT SHIPPING</div>
        <div className="search-post-card__heading">{title}</div>
        <Link className="search-post-card__button" to={link}>
          Read
        </Link>
      </div>
    </div>
  )
}

export default SearchPostCard
