import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import SearchPostCard from './SearchPostCard'

export default function RelatedBlogs() {
  return (
    <StaticQuery
      query={graphql`
        query RelatedBlogPostsQuery {
          allWpPost(sort: { fields: date, order: DESC }, limit: 4) {
            edges {
              node {
                id
                title
                excerpt
                authorId
                date(formatString: "MMMM DD, YYYY")
                slug
                uri
                featuredImage {
                  node {
                    localFile {
                      childImageSharp {
                        fluid(quality: 90, maxWidth: 200) {
                          ...GatsbyImageSharpFluid_withWebp
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={({ allWpPost }) => (
        <div className="blog-post__wrapper">
          <br />
          <br />
          <br />
          <div className="blog-post__heading">RELATED BLOGS</div>
          {allWpPost.edges.map(({ node }) => (
            <SearchPostCard
              title={node.title}
              featuredImage={
                node.featuredImage.node.localFile.childImageSharp.fluid
              }
              link={node.uri}
            />
          ))}
        </div>
      )}
    />
  )
}
