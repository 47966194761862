import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import { Col, Container, Image, Row } from 'react-bootstrap'
import Hero from '../components/EquipmentTabs/Hero'
import Footer from '../components/Footer'
import Freequote from '../components/Freequote'
import Getquote from '../components/Getquote'
import Layout from '../components/Layout'
import Location from '../components/Location'
import RelatedBlogs from '../components/RelatedBlogs'
import SEO from '../components/seo'
import fb from '../images/fb-icon.svg'
import linkedin from '../images/linkedin-icon.svg'
import plus from '../images/plus-icon.svg'
import youtubeorange from '../images/youtube-icon-orange.svg'


export const BlogPostTemplate = ({
  content,
  // categories,
  // tags,
  title,
  date,
  author,
  featuredImage,
}) => {
  return (
    <>
      <Hero type="primary" image={featuredImage} title={title} />
      <section className="blog-post">
        <Container className="blog-post__container" fluid={true}>
          <Row>
            <Col className="blog-post__col-left" xl={7}>
              <div dangerouslySetInnerHTML={{ __html: content }} />
            </Col>
            <Col className="blog-post__col-right" xl={4}>
              <div className="blog-post__icons-wrapper">
                <div className="blog-post__icon">
                  <a href="https://www.facebook.com/RoadLINX/" className="link">
                    <Image src={fb} />
                  </a>
                </div>
                <div className="blog-post__icon">
                  <a
                    href="https://www.linkedin.com/company/roadlinx/about/"
                    className="link"
                  >
                    <Image src={linkedin} />
                  </a>
                </div>
                <div className="blog-post__icon">
                  <a
                    href="https://www.youtube.com/channel/UCw-RZcawMBC2LpjkdMnV7FA/featured"
                    className="link"
                  >
                    <Image src={youtubeorange} />
                  </a>
                </div>
                <div className="blog-post__icon">
                  <a
                    href="https://plus.google.com/u/0/108831081307274188203"
                    className="link"
                  >
                    <Image src={plus} />
                  </a>
                </div>
              </div>
              <Getquote
                title="Get a quote today!"
                buttonLabel="Get a free estimate"
              />
              <RelatedBlogs />
            </Col>
          </Row>
        </Container>
      </section>
      <Freequote />
      <Location />
      <Footer />
    </>
  )
}

BlogPostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  title: PropTypes.string,
}

const BlogPost = ({ data }) => {
  const { wpPost: post } = data

  return (
    <Layout>
      <SEO title={post.seo.title} description={post.seo.metaDesc || ''} />
      <BlogPostTemplate
        content={post.content}
        categories={post.categories}
        tags={post.tags}
        title={post.title}
        date={post.date}
        author={post.author}
        featuredImage={post.featuredImage?.node.localFile.childImageSharp.fluid}
      />
    </Layout>
  )
}

BlogPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default BlogPost

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    wpPost(id: { eq: $id }) {
      id
      title
      slug
      content
      date(formatString: "MMMM DD, YYYY")
      authorId
      featuredImage {
        node {
          localFile {
            childImageSharp {
              fluid(quality: 90, maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
      seo {
        title
        metaDesc
      }
      # categories {
      #   name
      #   slug
      # }
      # tags {
      #   name
      #   slug
      # }
      # author {
      #   name
      #   slug
      # }
    }
  }
`
